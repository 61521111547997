import React, { useMemo } from "react"
import { useState } from "react"
import { parseISO, isBefore } from "date-fns"
import SaleItem from "./SaleItem"
import Container from "../Container/Container"
import { ThemeProviderV3, Text, Tabs, Tab, TabInfo } from "@artsy/palette"

interface ISaleProps {
  sales: Array<any>
}

const Sales = ({ sales }: ISaleProps) => {
  const [currentTab, setCurrentTab] = useState<TabInfo | undefined>({
    name: "Upcoming Auctions",
    data: "upcomingAuctions",
    tabIndex: -1,
  })

  const partitionedAuctions = useMemo(() => {
    const today = new Date()

    const auctions = sales.reduce(
      (acc, sale) => {
        const endDates = parseISO(sale.node.endDate)

        if (isBefore(endDates, today)) {
          return {
            ...acc,
            pastAuctions: [sale, ...acc.pastAuctions],
          }
        } else {
          return {
            ...acc,
            upcomingAuctions: [...acc.upcomingAuctions, sale],
          }
        }
      },
      { upcomingAuctions: [], pastAuctions: [] }
    )

    if (auctions.upcomingAuctions.length === 0) {
      setCurrentTab({
        name: "Past Auctions",
        data: "pastAuctions",
        tabIndex: -1,
      })
    }

    return auctions
  }, [sales])

  const salesItems = useMemo(() => {
    const visibleSales = partitionedAuctions[currentTab?.data]

    if (visibleSales.length === 0) {
      return (
        <div
          style={{ margin: 20, textAlign: "center" }}
        >{`There are no ${currentTab?.name}`}</div>
      )
    }

    return (
      <>
        <Text variant="xl">{currentTab?.name}</Text>
        {visibleSales.map((sale: any) => (
          <SaleItem sale={sale} />
        ))}
      </>
    )
  }, [partitionedAuctions, currentTab?.data, currentTab?.name])

  return (
    <ThemeProviderV3>
      <Container>
        {partitionedAuctions["upcomingAuctions"].length !== 0 ? (
          <Tabs
            fill
            justifyContent="center"
            initialTabIndex={0}
            onChange={activeTab => setCurrentTab(activeTab)}
          >
            <Tab data={"upcomingAuctions"} name="Upcoming Auctions" />
            <Tab data={"pastAuctions"} name="Past Auctions" />
          </Tabs>
        ) : null}

        {salesItems}
      </Container>
    </ThemeProviderV3>
  )
}

export default Sales
