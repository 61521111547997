import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import Container from "../Container/Container"
import { isAfter, parseISO } from "date-fns"
import { format } from "date-fns-tz"
import * as styles from "./FeaturedAuction.module.scss"
import { Button, ThemeProviderV3, Text } from "@artsy/palette"
import { getPathToSale } from "../../utils/path"
import { Link } from "gatsby"

interface IFeaturedAuctionProps {
  sales: Array<any>
}

const FeaturedAuction = ({ sales }: IFeaturedAuctionProps) => {
  const featuredSale = useMemo(() => {
    const today = new Date()
    return sales.find(sale => isAfter(parseISO(sale.node.endDate), today))
  }, [sales])

  const featuredImage = useMemo(
    () => getImage(featuredSale?.node?.mainMedia),
    [featuredSale]
  )

  const isUpcoming = useMemo(() => {
    return isAfter(parseISO(featuredSale?.node?.startDate), new Date())
  }, [featuredSale?.node?.startDate])

  const dateText = useMemo(() => {
    let text = "Starts on "
    let field = featuredSale?.node?.startDate

    if (!isUpcoming) {
      text = isAfter(new Date(), parseISO(featuredSale?.node?.endDate))
        ? "Ended on "
        : "Ends on "
      field = featuredSale?.node?.endDate
    }

    return field
      ? `${text} ${format(parseISO(field), "MMMM do, yyyy 'at' hh:mmaaa zzz")} `
      : ""
  }, [featuredSale?.node?.startDate, featuredSale?.node?.endDate, isUpcoming])

  if (!featuredSale) {
    return <div className={styles.spacer}></div>
  }

  return (
    <ThemeProviderV3>
      <Container className={styles.container}>
        <Text variant="xl" className={styles.header}>
          Featured Auction
        </Text>

        {featuredImage ? (
          <GatsbyImage
            style={{
              width: "100%",
              height: 600,
              marginBottom: 20,
              marginTop: 40,
            }}
            imgStyle={{ objectFit: "cover" }}
            image={featuredImage}
            alt={featuredSale.node.name}
          />
        ) : null}

        <div className={styles.saleContainer}>
          <div style={{ flex: 1, width: "100%" }}>
            {isUpcoming ? (
              <h5 className={styles.subheader}>Upcoming Auction</h5>
            ) : null}
            <Text variant="xl">{featuredSale?.node.name}</Text>
            <Text variant="xl" textColor="black60">
              {dateText}
            </Text>
          </div>

          <Link to={getPathToSale(featuredSale.node)}>
            <Button width="100%">View Auction</Button>
          </Link>
        </div>
      </Container>
    </ThemeProviderV3>
  )
}
export default FeaturedAuction
