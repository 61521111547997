import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useMemo } from "react"
import { Button, Text } from "@artsy/palette"
import ReactMarkdown from "react-markdown"
import { isAfter, parseISO } from "date-fns"
import { format } from "date-fns-tz"

import * as styles from "./SaleItem.module.scss"
import { getPathToSale } from "../../utils/path"
import { Link } from "gatsby"

interface ISaleItemProp {
  sale: any
}

const SaleItem = ({ sale }: ISaleItemProp) => {
  const image = useMemo(() => getImage(sale.node.mainMedia), [sale])

  const isUpcoming = useMemo(() => {
    return isAfter(parseISO(sale?.node?.startDate), new Date())
  }, [sale?.node?.startDate])

  const dateText = useMemo(() => {
    let text = "Starts on "
    let field = sale?.node?.startDate

    if (!isUpcoming) {
      text = isAfter(new Date(), parseISO(sale?.node?.endDate))
        ? "Ended on "
        : "Ends on "
      field = sale?.node?.endDate
    }

    return field
      ? `${text} ${format(parseISO(field), "MMMM do, yyyy 'at' h:mmaaa zzz")} `
      : ""
  }, [sale?.node?.startDate, sale?.node?.endDate, isUpcoming])

  const imageComponent = useMemo(() => {
    return image ? (
      <GatsbyImage
        style={{ flex: 1, width: "100%", height: "100%" }}
        imgStyle={{ objectFit: "contain" }}
        image={image}
        alt={sale.node.name}
      />
    ) : null
  }, [!!image, sale.node.name])

  return (
    <div className={styles.container}>
      <div style={{ flex: 1 }}>{imageComponent}</div>

      <div className={styles.infoContainer}>
        {isUpcoming ? (
          <Text
            variant="xs"
            textColor="black60"
            style={{ textTransform: "uppercase" }}
          >
            Upcoming
          </Text>
        ) : null}
        <Text variant="xl">{sale?.node.name}</Text>
        <Text variant="xl" textColor="black60">
          {dateText}
        </Text>
        <div className="lineBreak">
          <ReactMarkdown>{sale.node.heroHeadline.heroHeadline}</ReactMarkdown>
        </div>
        <Link to={getPathToSale(sale.node)}>
          <Button width="100%">View Auction</Button>
        </Link>
      </div>
    </div>
  )
}

export default SaleItem
