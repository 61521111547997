import * as React from "react"

import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Hero from "../components/Hero/Hero"
import FeaturedAuction from "../components/FeaturedAuction/FeaturedAuction"

import "../scss/index.scss"
import Sales from "../components/Sales/Sales"
import Page from "../components/Page/Page"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSale(sort: { fields: [endDate] }) {
        edges {
          node {
            id
            name
            featured
            heroHeadline {
              heroHeadline
            }
            slug
            description {
              description
            }
            startDate
            endDate
            mainMedia {
              file {
                contentType
                url
              }
              gatsbyImageData
              title
            }
          }
        }
      }
    }
  `)

  return (
    <Page>
      <Seo title="Artsy Auctions" />
      <Hero />
      <FeaturedAuction sales={data.allContentfulSale.edges} />
      <Sales sales={data.allContentfulSale.edges} />
    </Page>
  )
}

export default IndexPage
