import React from "react"

import * as styles from "./Hero.module.scss"
import Container from "../Container/Container"
import ContactUs from "../ContactUs/ContactUs"

const Hero = () => {
  return (
    <Container>
      <div className={styles.hero}>
        <div className={styles.heroInner}>
          <div>
            <h1>NFT Auctions</h1>
          </div>
        </div>

        <div className={styles.heroDescription}>
          <div className={styles.heroDescriptionInner}>
            <p>
              Bid on works you love with auctions on Artsy. With bidding opening
              daily, Artsy connects collectors like you to art from leading
              auction houses, nonprofit organizations and sellers across the
              globe. We feature premium artworks including modern, contemporary
              and street art, so you can find works by your favorite artist --
              and discover new ones -- all in one place.
            </p>
          </div>

          <div className={styles.heroDescriptionQuestion}>
            <ContactUs />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Hero
