import React from "react"
import * as styles from "./ContactUs.module.scss"

const ContactUs = () => (
  <div>
    <div className={styles.contactUsItem}>
      <span>Questions?</span>
      <a
        href="https://support.artsy.net/hc/en-us/articles/4420110097815-Placing-a-Bid"
        target="_blank"
        rel="noreferrer"
      >
        How to Bid on Artsy
      </a>
    </div>
    <div className={styles.contactUsItem}>
      <span>Contact us</span>
      <a href="mailto: specialist@artsy.net">specialist@artsy.net</a>
      <p>+1-646-381-3901</p>
    </div>
  </div>
)
export default ContactUs
